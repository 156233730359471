import { auth_apis } from "../constants/api";
import { apiCall } from "./base";
import { user_backend_host } from "../constants/hostsUrl";
import { isMeetingHost } from "../funcs/data";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../funcs/storage";
import { local_storage_keys } from "../constants/storage";
import { removeJwtToken } from "../funcs/auth";

export const getJwtToken = async ({
  userId,
  subDomain,
  meetingAttendeeRole,
}) => {
  let jwtToken = getLocalStorageItem(local_storage_keys.jwt_token);
  let storedUserId = getLocalStorageItem(local_storage_keys.user_id);
  if (userId !== storedUserId || !jwtToken) {
    removeJwtToken();
    removeLocalStorageItem(local_storage_keys.user_id);
    try {
      const userAuthTokenApiResponse = await apiCall({
        url: user_backend_host + auth_apis.fetch_auth_token,
        method: "POST",
        headers: {
          "exly-source": "exly_connect",
          "auth-token": "eyJtZXNzYWdlIjoiSldUIFJ1bGVzISIsImlhdCI6MTQ1OTQ0ODE",
          "scoot-origin": "web_app",
        },
        body: {
          client_id: userId,
          grant_type: "authorization_code",
          ...(isMeetingHost(meetingAttendeeRole)
            ? {
                refresh_token: "",
                staff_email: null,
              }
            : {
                token_type: 3,
                sub_domain: subDomain,
              }),
        },
      });

      jwtToken = userAuthTokenApiResponse?.data?.data?.tokens?.access_token;
      if (jwtToken) {
        setLocalStorageItem(local_storage_keys.jwt_token, jwtToken);
        setLocalStorageItem(local_storage_keys.user_id, userId);
      }
    } catch {}
  }
  return jwtToken;
};
