export const auth_codes = {
  auth_token_missing: {
    meaning: "Auth Token Missing",
    status_code: 1,
    http_status_code: 400,
  },
  invalid_auth_token: {
    meaning: "Invalid Auth Token",
    status_code: 2,
    http_status_code: 400,
  },
  access_token_missing: {
    meaning: "Access Token Missing",
    status_code: 3,
    http_status_code: 400,
  },
  invalid_jwt_token: {
    meaning: "Invalid JWT Token",
    status_code: 4,
    http_status_code: 401,
  },
  access_token_expired: {
    meaning: "Access Token Expired",
    status_code: 5,
    http_status_code: 401,
  },
  unknown_user: {
    meaning: "Unknown User",
    status_code: 6,
    http_status_code: 400,
  },
  refresh_token_expired: {
    meaning: "Refresh Token Expired",
    status_code: 7,
    http_status_code: 401,
  },
  refresh_token_missing: {
    meaning: "Refresh Token Missing",
    status_code: 8,
    http_status_code: 401,
  },
  invalid_platform: {
    meaning: "Platform not allowed",
    status_code: 9,
    http_status_code: 401,
  },
  invalid_credentails: {
    meaning: "Operations not allowed",
    status_code: 10,
    http_status_code: 401,
  },
};
