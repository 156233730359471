import React from "react";
import { useParams } from "react-router-dom";
import {
  default_leave_url,
  dynamic_route_var_names,
  meeting_attendee_roles,
} from "../../utils/constants/data";
import { getSessionStorageItem } from "../../utils/funcs/storage";
import { session_storage_keys } from "../../utils/constants/storage";
import { getHostLeaveUrl } from "../../utils/funcs/url";
import { getJwtToken } from "../../utils/apis/auth";
import { fetchMeetingDetails } from "../../utils/apis/meeting";
import { auth_codes } from "../../utils/constants/auth";
import { removeJwtToken } from "../../utils/funcs/auth";
import { checkIfMeetingUrlIsValid } from "../../utils/funcs/meetingAuthUrl";
import { logError } from "../../utils/funcs/logError";

const Client = () => {
  React.useEffect(() => {
    const isMeetingUrlValid = checkIfMeetingUrlIsValid({
      meetingAttendeeRole: meeting_attendee_roles.host,
    });

    if (isMeetingUrlValid) {
      getMeetingDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dynamicRouteParams = useParams();
  const meetingUuid = dynamicRouteParams[dynamic_route_var_names.meetingUuid];

  const getMeetingDetails = async () => {
    const { userEmail, userId, leaveUrl } =
      getSessionStorageItem(session_storage_keys.user_details) || {};

    let subDomain;
    try {
      subDomain = new URL(leaveUrl || default_leave_url).host.split(".")[0];
    } catch {
      subDomain = new URL(default_leave_url).host.split(".")[0];
    }

    try {
      const jwtToken = await getJwtToken({
        userId,
        subDomain,
        meetingAttendeeRole: meeting_attendee_roles.host,
      });
      const fetchMeetingDetailsApiResponse = await fetchMeetingDetails({
        meetingAttendeeRole: meeting_attendee_roles.host,
        jwtToken,
        meetingUuid,
        userEmail,
        shareStartUrl: true,
      });
      const fetchMeetingDetailsApiData = fetchMeetingDetailsApiResponse?.data;

      if (
        [
          auth_codes.unknown_user,
          auth_codes.refresh_token_expired,
          auth_codes.access_token_missing,
          auth_codes.invalid_jwt_token,
          auth_codes.access_token_expired,
        ]
          .map((i) => i.status_code)
          .includes(fetchMeetingDetailsApiData?.status)
      ) {
        // we need to refetch token and start again
        removeJwtToken();
        getMeetingDetails();
        return;
      }

      if (fetchMeetingDetailsApiData?.meetingStartUrl) {
        window.location.href = fetchMeetingDetailsApiData?.meetingStartUrl;
      } else {
        window.location.href = getHostLeaveUrl();
      }
    } catch (err) {
      logError({
        error: err,
        when: "getMeetingDetails function call",
        occuredAt: "src/pages/ZoomRedirect/ZoomRedirect.js",
      });
    }
  };

  return <div />;
};

export default Client;
