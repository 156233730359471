import { session_storage_keys } from "../../utils/constants/storage";
import { isMeetingHost } from "../../utils/funcs/data";
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/funcs/storage";
import {
  waiting_primary_btn_texts,
  stages,
  join_audio_button_text,
} from "./data";

const textIsJoinAudio = (text) =>
  text?.toLowerCase?.() === join_audio_button_text.toLowerCase();

export function autoJoinAudio() {
  let timer = setInterval(() => {
    const buttonNode = document.querySelector(
      "div.join-dialog button.zm-btn.join-audio-by-voip__join-btn.zm-btn--primary.zm-btn--lg"
    );
    if (buttonNode && textIsJoinAudio(buttonNode?.innerText)) {
      clearInterval(timer);
      buttonNode.click();
    }
  }, 100);
}

const textIsWaiting = (text) =>
  text?.toLowerCase?.() === waiting_primary_btn_texts.waiting.toLowerCase();
const textIsJoin = (text) =>
  text?.toLowerCase?.() === waiting_primary_btn_texts.join.toLowerCase();

// ISS = in session storage
const getStageISS = () =>
  getSessionStorageItem(session_storage_keys.stage) || stages.mount;
export const setStageISS = (stage) =>
  setSessionStorageItem(session_storage_keys.stage, stage);

/**
 * hacky solution to change zoom ui
 *
 * should be deleted when implemented from zoom's side
 */
export function autoJoinMeetOnHostJoin({ meetingAttendeeRole }) {
  if (
    isMeetingHost(meetingAttendeeRole) ||
    [stages.timer_started, stages.host_joined, stages.meeting_joined].includes(
      getStageISS()
    )
  ) {
    return;
  }

  setStageISS(stages.timer_started);

  let timer;

  function clearTimer() {
    removeSessionStorageItem(session_storage_keys.waiting_primary_btn_text);
    setStageISS(stages.host_joined);
    clearInterval(timer);
  }

  timer = setInterval(function () {
    const controlBtns = document.querySelector(".waiting-pannel-btn.controls");
    const primaryBtnNode = controlBtns?.firstChild;
    let primaryBtnText = primaryBtnNode?.innerText || "";
    // ISS = in session storage
    const primaryBtnTextISS =
      getSessionStorageItem(session_storage_keys.waiting_primary_btn_text) ||
      "";

    // check if text changed
    if (textIsJoin(primaryBtnText) && textIsWaiting(primaryBtnTextISS)) {
      clearTimer();
      primaryBtnNode.click();
    } else if (
      // first time values are not set
      textIsWaiting(primaryBtnText) &&
      !textIsWaiting(primaryBtnTextISS)
    ) {
      sessionStorage.setItem(
        session_storage_keys.waiting_primary_btn_text,
        primaryBtnText
      );
      const secondaryBtnNode = controlBtns?.lastChild;
      secondaryBtnNode?.setAttribute(
        "style",
        "color: #2d8cff !important; background-color: transparent !important; border: none !important;"
      );
    }
  }, 100);
}
