export const logError = async ({
  error,
  extraErrorData = undefined,
  occuredAt,
  when,
  severity = "medium",
  customErrorMessage = undefined,
  logToPlatforms = [["console"]],
}) => {
  const customError = customErrorMessage || {
    error,
    occuredAt,
    when,
    severity,
    extraErrorData,
  };

  logToPlatforms.forEach(([platform]) => {
    if (platform.includes("console")) {
      console.log(customError);
    }
  });
};
