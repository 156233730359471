export const local_storage_keys = {
  jwt_token: "jwt-token",
  user_id: "user_id",
  full_name: "full_name",
};

export const session_storage_keys = {
  stage: "stage",
  waiting_primary_btn_text: "waiting_primary_btn_text",
  user_details: "userDetails",
};
