export const stages = {
  mount: "mount",
  fetch_meeting_details_api_failed: "fetch_meeting_details_api_failed",
  meeting_initiated: "meeting_initiated",
  timer_started: "timer_started",
  host_joined: "host_joined",
  meeting_joined: "meeting_joined",
};

export const waiting_primary_btn_texts = {
  join: "joining",
  waiting: "waiting...",
};

export const join_audio_button_text = "Join Audio by Computer";
