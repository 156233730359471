import React from "react";
import styles from "./MeetingNotStarted.module.css";

export const MeetingNotStarted = ({ leaveUrl }) => {
  return (
    <div className={styles.meetingNotStartedContainer}>
      <h1>Meeting has not yet started.</h1>
      <div className={styles.buttonsConatainer}>
        {/* <button
          className={styles.goBackButton}
          onClick={() => {
            window.location.href = leaveUrl;
          }}
        >
          Go Back
        </button> */}
        <button
          className={styles.refreshPageButton}
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
};
