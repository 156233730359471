import React from "react";
import styles from "./BodyWrapper.module.css";

export const BodyWrapper = ({ children }) => {
  return (
    <div className={styles.exlyBodyContainer}>
      <div className={styles.exlyBranding}>
        <img src="/logo192.png" width={40} height={40} alt="Exly Connect" />
        <h1>Exly Connect</h1>
      </div>
      {children}
    </div>
  );
};
