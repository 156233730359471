export const apiCall = ({ url, method = "GET", body, headers }) =>
  fetch(url, {
    method: method || "GET",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  }).then((response) =>
    response.json().then((data) => ({ status: response.status, data }))
  );
