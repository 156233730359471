import {
  default_leave_url,
  meeting_attendee_roles,
  user_params,
} from "../constants/data";
import { session_storage_keys } from "../constants/storage";
import { getSessionStorageItem, setSessionStorageItem } from "./storage";
import { getHostLeaveUrl } from "./url";

export const checkIfMeetingUrlIsValid = ({ meetingAttendeeRole }) => {
  // First we check the user details that are there in the params
  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get(user_params.user_email);
  const userName = urlParams.get(user_params.user_name);
  const userId = urlParams.get(user_params.user_id);
  const leaveUrl = decodeURIComponent(
    urlParams.get(user_params.from) || default_leave_url
  );

  // Then check if there are user details already their in session storage
  const preUserDetails =
    getSessionStorageItem(session_storage_keys.user_details) || {};

  // Check if user details are in session storage
  const isUserDetailsInSessionStorage = Object.keys(preUserDetails).length;

  // If there was data in the url params
  if (userEmail && userName && userId && leaveUrl) {
    // if the user is customer, then we simple set the data in session storage
    setSessionStorageItem(session_storage_keys.user_details, {
      userEmail,
      userName,
      userId,
      leaveUrl,
    });
  }
  // If no data in url params and session storage
  else if (!isUserDetailsInSessionStorage) {
    // if the user is host
    if (meetingAttendeeRole === meeting_attendee_roles.host) {
      // if user is host and no url params and no respective session storage data, then we redirect back to CT
      window.location.href = getHostLeaveUrl();
      return null;
    } else {
      // Todo: If need to redirect customer somewhere, it can be done here
      window.location.href = leaveUrl;
      return null;
    }
  }

  if (meetingAttendeeRole === meeting_attendee_roles.host) {
    // If the user is not redirected, we clear the url params
    // Here we clear all the params, as we have to make sure no user can
    // get into the meeting as host provided they have the meeting link,
    // For redirection we have a fixed domain to redirect the user to which is CT, exly-connect route
    window.history.replaceState(null, "", window.location.pathname);
  } else {
    const newUrlParams = new URLSearchParams();
    const fromParam = urlParams.get(user_params.from) || "";
    if (fromParam) {
      // Incase of customer, we remove all the params except the 'from' param as it contains
      // the url of the user's 'my-bookings' page. Incase someone share the meeting url and tries to access
      // it then we prevent them and redirect to this 'from' url
      newUrlParams.set(user_params.from, fromParam);
      window.history.replaceState(
        null,
        "",
        `${window.location.pathname}?${newUrlParams.toString()}`
      );
    } else {
      window.history.replaceState(null, "", window.location.pathname);
    }
  }

  return true;
};
